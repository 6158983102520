<!--
  发布服务
    + 一般机构
    + 服务机构
 -->
<template>
  <div class="detail">
    <detail-header title="发布服务" />
    <div class="detail_main page_width">
      <div class="main_content" v-loading="loading">
        <!-- 选择类型 -->
        <el-form
          ref="serviceTypeForm"
          :model="serviceTypeForm"
          :rules="serviceTypeRules"
          size="small"
          label-width="180px"
          label-position="left">
          <el-form-item label="服务类型" prop="serviceId">
            <el-cascader
              style="width: 100%;"
              placeholder="请选择服务类型"
              @change="onServiceIdChange"
              :loading="selectLoading"
              :props="{
                value: 'id',
                label: 'name',
                emitPath: false
              }"
              :options="serviceOptions"
              v-model="serviceTypeForm.serviceId">
            </el-cascader>
          </el-form-item>
        </el-form>
        <!-- 自定义表单 -->
        <custom-form
          ref="customForm"
          mode="enter"
          :formModel="fieldList"
          :formData="formData">
          <custom-form-item
            v-for="item in fieldList"
            :key="item.column"
            :item="item"
            :value.sync="formData[item.column]">
          </custom-form-item>
        </custom-form>

        <div class="btn_box">
          <el-button
            type="primary"
            size="small"
            :loading="btnLoading"
            @click="submitForm">
            发布服务
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'
export default {
  data () {
    return {
      loading: false,
      // 提交按钮的Loading
      btnLoading: false,
      // 服务类型下拉面板loading
      selectLoading: false,
      // 选择服务类型表单
      serviceTypeForm: {
        serviceId: '' // 三级服务类型id
      },
      serviceTypeRules: {
        serviceId: [
          { required: true, message: '请选择服务类型', trigger: 'change' }
        ]
      },
      serviceOptions: [], // 服务类型选项
      // 字段数组
      fieldList: [],
      // 表单数据
      formData: {}
    }
  },
  components: {
    customForm,
    customFormItem
  },
  created () {
    this.getServiceTypeList()
  },
  methods: {
    // 获取级联服务类型
    getServiceTypeList () {
      this.selectLoading = true
      api.getServiceTypeCheckList().then(res => {
        if (res.data.code === 0) {
          this.serviceOptions = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.selectLoading = false
      })
    },
    // 获取表单的详情
    getFormByService () {
      this.loading = true
      api.getFormByService(this.serviceTypeForm.serviceId).then(res => {
        if (res.data.code === 0) {
          console.log('服务表单', res.data.data)
          this.fieldList = res.data.data.fields
          this.formData = res.data.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 发布新服务
    createService (data) {
      this.btnLoading = true
      api.createService(data).then(res => {
        if (res.data.code === 0) {
          this.$message.success('发布服务成功！')
          const { serviceTypeId, title, description } = data.service
          // 跳转到发布服务成功页面
          this.$router.push({
            path: '/service-create-success',
            query: {
              serviceTypeId: serviceTypeId,
              title: title,
              content: description,
              keywords: title
            }
          })
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    },

    // 服务类型发生变化
    onServiceIdChange () {
      this.fieldList = []
      this.formData = {}
      this.getFormByService()
    },
    // 提交表单
    submitForm () {
      this.$refs.serviceTypeForm.validate((valid) => {
        if (valid) {
          this.validateCustomForm()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 验证自定义表单
    async validateCustomForm () {
      try {
        const data = await this.$refs.customForm.validate()
        const fixedData = {}
        const columnData = {}
        // 把固定字段表单项和非固定自定表单项值隔离开来
        this.fieldList.forEach(item => {
          if (item.isFixed) {
            fixedData[item.column] = data[item.column]
          } else {
            columnData[item.column] = data[item.column]
          }
        })
        // 创建服务所需要的数据
        const serviceData = {
          service: {
            serviceTypeId: this.serviceTypeForm.serviceId,
            ...fixedData
          },
          formData: {
            ...columnData
          }
        }
        console.log('serviceData', serviceData)
        this.createService(serviceData)
      } catch (err) {
        console.log(err)
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;

      .btn_box {
        padding: 30px 0 0 180px;
      }
    }
  }
}
</style>
